import Sidebar from "../sidebar/Sidebar";
import "./navbar.scss";
import { motion } from "framer-motion";
import logo from "../../assets/logo-transparent-png.png"
const Navbar = () => {
  return (
    <div className="navbar">
      {/* Sidebar */}
      <Sidebar/>
      <div className="wrapper">
        <motion.img
        className="logo"
          src={logo}
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        />
          
       
        <div className="social">
          <a href="tel:+40729436991">
           +40 729 436 991
          </a>
          <a href="https://www.instagram.com/webdesign.concept" target="_blank">
            <img src="/instagram.png" alt="" />
          </a>
      
      
        </div>
      </div>
    </div>
  );
};

export default Navbar;
