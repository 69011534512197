import { useRef } from "react";
import "./services.scss";
import { motion, useInView } from "framer-motion";

const variants = {
  initial: {
    x: -500,
    y: 100,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    },
  },
};

const Services = () => {
  const ref = useRef();

  const isInView = useInView(ref, { margin: "-100px" });

  return (
    <motion.div
      className="services"
      variants={variants}
      initial="initial"
      // animate="animate"
      // whileInView="animate"
      ref={ref}
      animate={"animate"}
    >
      <motion.div className="textContainer" variants={variants}>
        <p>
          Focus pe cresterea brandului
          <br /> si dezvoltarea ta
        </p>
        <hr />
      </motion.div>
      <motion.div className="titleContainer" variants={variants}>
        <div className="title">
          <img src="/people.webp" alt="" />
          <h1>
            <motion.b whileHover={{ color: "#e4c8ba" }}>Idei</motion.b> unice
          </h1>
        </div>
        <div className="title">
          <h1>
            <motion.b whileHover={{ color: "#e4c8ba" }}>Pentru</motion.b> Tine.
          </h1>
          <button>Ce facem?</button>
        </div>
      </motion.div>
      <motion.div className="listContainer" variants={variants}>
        <motion.div
          className="box"
          whileHover={{ background: "#e4c8ba", color: "black" }}
        >
          <h2>Website Prezentare</h2>
          <p>
            Achizitionarea gazduirii si a domeniului web Stabilirea scopului
            principal al site-ului Pregatirea produselor, serviciilor si
            materialelor de publicat in site Crearea site-ului si publicarea lui
            online Optimizarea SEO a site-ului.
          </p>
        </motion.div>
        <motion.div
          className="box"
          whileHover={{ background: "#e4c8ba", color: "black" }}
        >
          <h2>Blog Website</h2>
          <p>
            Achizitionarea gazduirii si a domeniului web. Stabilirea scopului
            principal al site-ului. Pregatirea contentului si a imaginilor,
            videoclipurilor, materialelor de publicat in site. Crearea site-ului
            si publicarea lui online. Optimizarea SEO a site-ului.
          </p>
        </motion.div>
        <motion.div
          className="box"
          whileHover={{ background: "#e4c8ba", color: "black" }}
        >
          <h2>E-commerce</h2>
          <p>
            Achizitionarea gazduirii si a domeniului web. Stabilirea scopului
            principal al site-ului E-commerce. Pregatirea produselor, contentului si a imaginilor,
            serviciilor si materialelor de publicat in site. Crearea site-ului
            si publicarea lui online. Optimizarea SEO a site-ului.
          </p>
        </motion.div>
        <motion.div
          className="box"
          whileHover={{ background: "#e4c8ba", color: "black" }}
        >
          <h2>Landing Pages</h2>
          <p>
            Pregatirea contentului, alegerea culorilor si trimiterea imaginilor, videoclipurilor catre noi, apoi publicarea in landing page-ului.
            Crearea paginei si publicarea lui online.
          </p>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Services;
