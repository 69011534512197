import { useRef } from "react";
import "./portfolio.scss";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";

const items = [
  {
    id: 1,
    title: "Website Prezentare",
    img: "https://pmvmedia.ro/wp-content/uploads/2022/11/3.png",
    desc: "Website-ul personal este modalitatea prin care te exprimi într-un mod creativ, oportunitate pe care nu o ai întotdeauna în CV. De la primul paragraf, pe care merită să îl gândeşti astfel încât să atragă atenţia, la design, povesteşti ceva despre tine. De asemenea, se poate realiza un site de prezentare al firmei sau al afacerii tale. Realizarea site-ului de prezentare profesional reprezintă o componentă foarte importantă din strategia de marketing digital a unei companii. Site-ul conține de regulă pagini cu informații despre domeniul de activitate, istoric, misiune, valori, serviciile sau produsele firmei, noutăți și date de contact.",
  },
  {
    id: 2,
    title: "Blog Website",
    img: "https://assets.hongkiat.com/uploads/50-most-beautiful-blogger-templates/Angel-Blogger-Template.jpg",
    desc: "Un blog, pentru început am putea să spunem că este un fel de site pe internet, un fel de jurnal public. În acest jurnal poți să povestești întâmplări, poți să împărtășești cunoștințe, poți să-ți dai cu părerea. Practic,  este un loc unde poți scrie ce vrei și cei interesați să vadă. Poate contine poze, videoclipuri, ce vrei tu. Chiar dacă sunt și companii care au blog, diferența conceptuală dintre blog și site este dată de scopul acestuia, de abordare. Practic un blog este tot un site, doar că diferă abordarea. În blog avem ideea de jurnal și de cronologie îns primul rând. Un site este mai mult un fel de showroom, un fel de loc în care se prezintă ceva de vânzare, de obicei. Practic, când spui site personal este același lucru ca și blogul. La fel cum intr-un site se poate adauga sectiunea de blog, aceasta este alegerea fiecaruia.",
  },
  {
    id: 3,
    title: "E-Commerce",
    img: "https://easy-sales.com/blog/ro/wp-content/uploads/2022/10/eCommerce-canal.jpg",
    desc: "Un magazin virtual sau magazin electronic (e-commerce) este un website de comerț electronic. Rolul său este să te ajute pe tine să îți aduci afacerea și în online, dacă este cazul, sau doar să vinzi anumite produse și servicii folosind mediul online.",
  },
  {
    id: 4,
    title: "Landing Pages",
    img: "https://www.optimizepress.com/wp-content/uploads/2022/09/ecommerce-landing-page.png",
    desc: "În marketingul digital, un landing page este o pagină web de sine stătătoare, creată special pentru o campanie de marketing sau de publicitate. Este locul în care un vizitator ajunge după ce a făcut click pe un link dintr-un newsletter sau pe reclame de pe Google, Bing, YouTube, Facebook, Instagram, Twitter sau alte locuri similare de pe web. Spre deosebire de paginile web, care au de obicei mai multe obiective și încurajează explorarea, landing page-urile sunt concepute cu un singur obiectiv sau scop, cunoscut sub numele de call to action (sau CTA, pe scurt). Se poate opta si pentru adaugarea sectiunii de blog, unui website de prezentare.",
  },
];

const Single = ({ item }) => {
  const ref = useRef();

  const { scrollYProgress } = useScroll({
    target: ref,
  });

  const y = useTransform(scrollYProgress, [0, 1], [-300, 300]);

  return (
    <section >
      <div className="container">
        <div className="wrapper">
          <div className="imageContainer" ref={ref}>
            <img src={item.img} alt="" />
          </div>
          <motion.div className="textContainer" style={{y}}>
            <h2>{item.title}</h2>
            <p>{item.desc}</p>
          
          </motion.div>
        </div>
      </div>
    </section>
  );
};

const Portfolio = () => {
  const ref = useRef();

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "start start"],
  });

  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
  });

  return (
    <div className="portfolio" ref={ref}>
      <div className="progress">
        <h1>Web Development</h1>
        <motion.div style={{ scaleX }} className="progressBar"></motion.div>
      </div>
      {items.map((item) => (
        <Single item={item} key={item.id} />
      ))}
    </div>
  );
};

export default Portfolio;
